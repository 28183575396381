<template>
  <div>
    <div class="screenOnly content">
      <div class="box">
        <p class="title">RETIRE SUA SENHA</p>

        <div class="buttons"></div>
        <button
          :disabled="toPrinter"
          @click="getTicket(true)"
          class="button is-large is-fullwidth mb-4 is-primary"
        >
          Prioridade
        </button>
        <button
          :disabled="toPrinter"
          @click="getTicket(false)"
          class="button is-large is-fullwidth is-primary"
        >
          Normal
        </button>
      </div>
    </div>
    <div class="printOnly has-text-centered m-0 pt-3 pb-3">
      <p class="title">
        {{ ticket.number }} <span>{{ ticket.priority | type }}</span>
      </p>
      <p class="subtitle mb-1">
        {{ ticket.start.date | date }} {{ ticket.start.time }}
      </p>
      <p class="subtitle">{{ $store.getters.terminal.name }}</p>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  name: "Ticket",
  data() {
    return {
      ticket: { start: {}, end: {} },
      toPrinter: false,
    };
  },
  filters: {
    type: function (v) {
      return v ? "P" : "N";
    },
    date: function (v) {
      if (!v) return;
      return format(parseISO(v), "dd/MM/yyyy");
    },
  },
  methods: {
    getTicket(priority) {
      const self = this;
      self.toPrinter = true;
      const date = new Date();
      const ticket = {
        start: {
          date: format(date, "yyyy-MM-dd"),
          time: format(date, "HH:mm"),
        },
        terminal: self.$route.params.id,
        priority: priority == true,
      };
      self.$http
        .post(`${process.env.VUE_APP_URI}ticket`, ticket)
        .then((response) => {
          self.ticket = response.data;
          setTimeout(function () {
            window.print();
            self.toPrinter = false;
          });
        });
    },
  },
  created() {
    //this.$store.commit("setToken");
  },
};
</script>
<style>
html,
body {
  height: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/*for printer*/
@media print {
  .screenOnly {
    display: none;
  }
  /* write your css rules*/
  @page {
    size: 5.8cm 5.8cm;
    margin: 0cm;
  }
  .title {
    font-size: 68px;
  }
  .subtitleff {
    font-size: 38px;
  }
  p {
    font-family: "Times New Roman", Times, serif;
  }
}
/*for desktop*/
@media screen {
  .printOnly {
    display: none;
  }
  /*for all display view*/
}
</style>